import $ from "jquery";
import "bootstrap";
import "jquery.easing";
var choices = [
    require("../img/cards/steal1.png"),
    require("../img/cards/steal2.png"),
    require("../img/cards/move1.png"),
    require("../img/cards/move2.png"),
    require("../img/cards/take1.png"),
    require("../img/cards/take2.png"),
    require("../img/cards/swap1.png"),
    require("../img/cards/swap2.png"),
    require("../img/cards/againA.png"),
    require("../img/cards/againB.png"),
    require("../img/cards/wild.png"),
    require("../img/cards/pick2.png")
];

var mouseX = 0;
var mouseY = 0;
window.addEventListener("mousemove", function (ev) {
    mouseX = ev.clientX / this.window.innerWidth;
    mouseY = ev.clientY / this.window.innerHeight;
});
// Smooth scrolling using jQuery easing
$('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            $('html, body').animate({
                scrollTop: (target.offset().top - 48)
            }, 1000, "easeInOutExpo");
            return false;
        }
    }
});

// Closes responsive menu when a scroll trigger link is clicked
$('.js-scroll-trigger').click(function () {
    $('.navbar-collapse').collapse('hide');
});

// Activate scrollspy to add active class to navbar items on scroll
$('body').scrollspy({
    target: '#mainNav',
    offset: 54
});

// Collapse Navbar
var navbarCollapse = function () {
    if ($("#mainNav").offset().top > 100) {
        $("#mainNav").addClass("navbar-shrink");
    } else {
        $("#mainNav").removeClass("navbar-shrink");
    }
};
// Collapse now if page is not at top
navbarCollapse();
// Collapse the navbar when page is scrolled
$(window).scroll(navbarCollapse);
var rX = 1;
var rY = 0;
var rZ = 0;
var rA = 0;
var dir = 1;
var nn = 0;
window.setInterval(function () {
    for (var i = 0; i < 10; i++) {
        var fD = Math.cos(rA) < 0;
        rA += 0.01 * dir;
        var flip = (Math.cos(rA) < 0) && (!fD);
        if (flip) {

            nn = (nn + 1 + Math.floor(Math.random() * (choices.length - 1))) % choices.length;
            var imgn = choices[nn];
            $(".card-mockup .card-front img").attr("src", `${imgn}`);
        }
        // rA = Math.min(Math.max(rA, Math.PI / 2), Math.PI * 2);
        rY += -rX / 1000 * dir; //(Math.random() - 0.5) * 0.1;
        rX += rY / 1000 * dir; //(Math.random() - 0.5) * 0.1;
        // rZ += -rX / 1000 + rY / 1000; //(Math.random() - 0.5) * 0.1;
        var len = Math.sqrt(rX * rX + rY * rY + rZ * rZ);
        rX = rX / len;
        rY = rY / len;
        rZ = rZ / len;
    }
    $(".card-mockup").css("transform", `translate(-50%,-50%) translateZ(-200px) rotate3d(${rX},${rY},${rZ},${rA}rad)`);
}, 25);
$(".card-mockup").on("click", function () {
    dir = -dir;
    // for (var i = 0; i < 10; i++) {
    //     rA += 0.01;
    //     // rA = Math.min(Math.max(rA, Math.PI / 2), Math.PI * 2);
    //     rY += -rX / 1000; //(Math.random() - 0.5) * 0.1;
    //     rX += rY / 1000; //(Math.random() - 0.5) * 0.1;
    //     // rZ += -rX / 1000 + rY / 1000; //(Math.random() - 0.5) * 0.1;
    //     var len = Math.sqrt(rX * rX + rY * rY + rZ * rZ);
    //     rX = rX / len;
    //     rY = rY / len;
    //     rZ = rZ / len;
    // }
})